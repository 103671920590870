
  @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@import '~leaflet/dist/leaflet.css';

.font-loader {
  font-family: 'Lato', sans-serif;
 }

.font-link {font-family: 'Lato', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'Lato', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
/* global.css */
:root {font-family: 'Lato', sans-serif;
  }
  
  
  * {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }


.leaflet-container {
  height: 600px;
  width: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}
 /*   font-family: 'Lato', sans-serif;
 font-family: 'Montserrat', sans-serif;
  font-family: 'Work Sans', sans-serif;*/